import type { PricedProduct } from '@medusajs/medusa/dist/types/pricing';
import { formatPrice } from '@src/lib/medusa/format-price';
import { getCheapestVariant } from '@src/lib/medusa/get-cheapest-variant';
import { getThumbnailUrl } from '@src/lib/medusa/get-thumbnail-url';
import { useCart } from '@src/lib/medusa/hooks/use-cart';
import { ImageWithFallback } from '@src/modules/shared/components/image-with-fallback';
import Link from 'next/link';
import type { ReactElement } from 'react';
import { useMemo } from 'react';

import styles from './preview.module.scss';

export function ProductPreview({
	product, //
}: {
	product: PricedProduct;
}): ReactElement | null {
	const titleStr = product.title || 'Untitled';
	const thumbnailSrc = getThumbnailUrl(product);
	const cheapestVariant = useMemo(() => getCheapestVariant(product.variants), [product]);
	const formattedPrice = formatPrice(cheapestVariant?.calculated_price);
	const detailsUrl = `/products/${product.handle!}/${product.id!}`;
	const { currency_code } = useCart();

	const isDownloadOnly =
		cheapestVariant && //
		!!cheapestVariant.metadata?.digital_asset_url &&
		(cheapestVariant?.calculated_price || 0) <= 0;

	return (
		<div className={styles.main}>
			<div className={styles.thumbnailWrapper}>
				<Link className={styles.thumbnail} href={detailsUrl}>
					<ImageWithFallback src={thumbnailSrc} alt={product.title} />
				</Link>
			</div>
			<div className={styles.details}>
				<div className={styles.nameAndPrice}>
					<Link className="leading-snug" href={detailsUrl}>
						{titleStr}
					</Link>
					<div className={styles.unitPrice}>
						{formattedPrice} {currency_code}
					</div>
				</div>
				{isDownloadOnly && <div className="text-xs text-primary font-semibold">Download Only</div>}
			</div>
		</div>
	);
}
